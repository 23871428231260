import { setServiceSetting } from "@springtree/eva-sdk-core-service";
import { NextRouter } from "next/router";
import { useTranslations } from "next-intl";
import { createContext, useContext, useEffect, useMemo } from "react";

import { mapLocale } from "~/services/algolia-utils";
import Eva from "~/services/eva";
import crossCountryUtils from "~/utils/cross-country-utils";

export type SortByItem = {
  /**
   * The name of the index to target.
   */
  value: string;
  /**
   * The label of the index to display.
   */
  label: string;
};

const Context = createContext<
  | {
      eva: Eva;
      organizationUnitId: number;
      configuration: EVA.Core.GetApplicationConfiguration;
      algoliaIndexId: string;
      algoliaSorting: SortByItem[];
    }
  | undefined
>(undefined);

export default function ConfigurationProvider(props: {
  organizationUnitId: number;
  configuration: EVA.Core.GetApplicationConfiguration;
  children: React.ReactNode;
  router: NextRouter;
}) {
  const locale = crossCountryUtils.getCurrentLocaleWithCountryUppercase(props.router);
  const t = useTranslations();

  const eva = useMemo(
    () => new Eva({ organizationUnitId: props.organizationUnitId, locale }),
    [locale, props.organizationUnitId]
  );

  const algoliaIndexId = useMemo(() => {
    return mapLocale(props.organizationUnitId, locale);
  }, [locale, props.organizationUnitId]);

  const algoliaSorting = useMemo(() => {
    return [
      { label: t("generic.sort_by_featured"), value: algoliaIndexId },
      { label: t("generic.sort_by_price_asc"), value: `${algoliaIndexId}_price_asc` },
      { label: t("generic.sort_by_price_desc"), value: `${algoliaIndexId}_price_desc` },
    ];
  }, [algoliaIndexId, t]);

  useEffect(() => {
    setServiceSetting("requestedOrganizationUnitID", props.organizationUnitId);
  }, [props.organizationUnitId]);

  return (
    <Context.Provider
      value={{
        eva,
        organizationUnitId: props.organizationUnitId,
        configuration: props.configuration,
        algoliaIndexId,
        algoliaSorting,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

/**
 * NOTE eva instance do not consider user status,
 * use `hooks.useCallService` for user dependant calls
 */
export function useConfiguration() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useConfiguration must be used within a ConfigurationProvider`);
  }

  return context;
}

export function useObjectConfigurationValue<T extends keyof EVA.Core.GetApplicationConfiguration>(key: T) {
  const { configuration } = useConfiguration();
  const value = configuration?.[key];

  try {
    if (typeof value === "string") {
      return JSON.parse(value) as EVA.Core.GetApplicationConfiguration[T];
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}
