import "nprogress/nprogress.css";
import "~/styles/globals.scss";

import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { setLogLevel } from "@springtree/eva-sdk-core-logger";
import { setCoreSetting } from "@springtree/eva-sdk-core-settings";
import {
  EvaRecoilRoot,
  getReactRecoilSettings,
  IUserExpiredCallbackParameters,
  setReactRecoilSetting,
  state,
} from "@springtree/eva-sdk-react-recoil";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SpeedInsights } from "@vercel/speed-insights/next";
import type { AppProps } from "next/app";
import localFont from "next/font/local";
import Router, { useRouter } from "next/router";
import { AbstractIntlMessages, IntlErrorCode, NextIntlClientProvider } from "next-intl";
import nProgress from "nprogress";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSetRecoilState } from "recoil";
import aa from "search-insights";

import CartChecker from "~/components/common/cart-checker";
import CountryDetectModal from "~/components/common/country-detect-modal/country-detect-modal";
import DatadogInit from "~/components/common/datadog-init";
import DidomiIntegration from "~/components/common/didomi-integration";
import GenericError from "~/components/common/generic-error";
import GlobalNotification from "~/components/common/global-notification";
import GTMIntegration from "~/components/common/gtm-integration";
import UsableNetIntegration from "~/components/common/usable-net-integration";
import PreviewModeBar from "~/components/layout/preview-mode-bar";
import { AuthContextProvider } from "~/contexts/auth-context-provider";
import BreakpointProvider from "~/contexts/breakpoint";
import ConfigurationProvider from "~/contexts/configuration";
import FavouriteStoreProvider from "~/contexts/favourite-store";
import { PageTrackingsProvider } from "~/contexts/page-trackings";
import { ServerSidePropsProvider } from "~/contexts/server-side-props";
import TextDirProvider from "~/contexts/text-dir";
import UserConsentsProvider from "~/contexts/user-consents";
import Firebase from "~/services/firebase";
import Logger from "~/services/logger/logger";
import CommonUtils from "~/utils/common-utils";
import Constants from "~/utils/constants";
import contentfulUtils from "~/utils/contentful-utils";
import intlUtils from "~/utils/intl-utils";
import { withStoragesAvailable } from "~/utils/storage-utils";

import packageJson from "../../package.json";

nProgress.configure({ showSpinner: false, minimum: 0.1, speed: 300, trickleSpeed: 100 });
Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

type Props = {
  organizationUnitId: number;
  configuration: EVA.Core.GetApplicationConfiguration;
  messages?: AbstractIntlMessages;
  dehydratedState?: unknown;
  draftMode?: boolean;
  timeZones: string[];
};

setCoreSetting("appName", packageJson.name);
setCoreSetting("appVersion", packageJson.version);

setLogLevel("error");

setReactRecoilSetting("shoppingCartOptions", {
  ...getReactRecoilSettings().shoppingCartOptions,
  IncludeAvailableShippingMethods: true,
  IncludeAvailablePaymentMethods: true,
  IncludePaymentTransactionActions: true,
  ProductProperties: Constants.CART_INCLUDED_FIELDS,
});

const font = localFont({
  src: [
    {
      path: "../../public/font-thin.woff2",
      weight: "100",
    },
    {
      path: "../../public/font-extralight.woff2",
      weight: "200",
    },
    {
      path: "../../public/font-light.woff2",
      weight: "300",
    },
    {
      path: "../../public/font-regular.woff2",
      weight: "400",
    },
    {
      path: "../../public/font-semibold.woff2",
      weight: "600",
    },
    {
      path: "../../public/font-bold.woff2",
      weight: "700",
    },
  ],
});

function onError(error: { code: any }) {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    // Missing translations are expected and should only log an error
    console.warn(error);
  } else {
    // Other errors indicate a bug in the app and should be reported
    console.error(error);
  }
}

export default function App({ Component, pageProps }: AppProps<Props>) {
  const router = useRouter();
  const [queryClient] = useState(new QueryClient({}));
  const setCurrentUserToken = useSetRecoilState(state.currentUser.currentUserTokenState);

  const userExpiredCallback = (params: IUserExpiredCallbackParameters) => {
    console.warn("User token expired", params.expiredToken);

    // At this point you can provide a model for the user to log back in
    // or unset the current user token
    //
    if (params.currentToken === params.expiredToken) {
      setCurrentUserToken("");
      Firebase.instance.signOut(); // [TS]: It appears that I can use the useAuth hook here, cause it depends on multiple EVA's services that needs the recoil root.
    }
  };

  const [lang, country] = router.locale?.split("-") ?? ["en", "ae"];

  // handle algolia insights init
  useEffect(() => {
    aa("init", {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APPID!,
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_APIKEY!,
    });
  }, []);

  // handle algolia query id sync when a new tab is open
  useEffect(() => {
    withStoragesAvailable((localStorage, sessionStorage) => {
      if (sessionStorage.getItem("algolia_queryid") === null) {
        const fromLocal = localStorage?.getItem("algolia_queryid");
        if (fromLocal) {
          sessionStorage.setItem("algolia_queryid", fromLocal);
          localStorage.removeItem("algolia_queryid");
        }
      }
    });
  }, [router.asPath]);

  // to debug route changes
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      Logger.instance.debug("Router: routeChangeStart to: ", url);
    };

    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    // Update the current `lang` and `dir` for the html tag dynamically.
    const html = document.querySelector("html")!;

    const dir = lang === "ar" ? "rtl" : "ltr";

    html.setAttribute("lang", lang);
    html.setAttribute("dir", dir);

    CommonUtils.isWindows() && document.body.classList.add(Constants.SCROLLBAR_CLASSNAME);

    // Update the current EVA language
    if (country) {
      setCoreSetting("language", `${lang}-${country.toUpperCase()}`);
    }
  }, [country, lang, router.locale]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/next-service-worker.js");
    }
  }, []);

  return (
    <>
      <style jsx global>{`
        :root {
          --local-font: ${font.style.fontFamily};
        }
      `}</style>
      <DatadogInit />
      <NextIntlClientProvider
        locale={intlUtils.getSupportedLocale(router)}
        timeZone={pageProps.timeZones?.[0]}
        messages={router.query.debugLabels ? {} : pageProps.messages}
        onError={onError}
      >
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={router.locale}>
          <ErrorBoundary fallbackRender={() => <GenericError {...pageProps} />}>
            <BreakpointProvider>
              <TextDirProvider>
                <ConfigurationProvider
                  organizationUnitId={pageProps.organizationUnitId}
                  configuration={pageProps.configuration}
                  router={router}
                >
                  <ServerSidePropsProvider serverSideProps={pageProps}>
                    <EvaRecoilRoot
                      endpointUrl={process.env.NEXT_PUBLIC_EVA_BACKEND_URL}
                      userExpiredCallback={(params) => userExpiredCallback(params)}
                    >
                      <AuthContextProvider>
                        <ContentfulLivePreviewProvider
                          locale={contentfulUtils.mapLocale(router.locale)}
                          enableInspectorMode={pageProps.draftMode ?? false}
                          enableLiveUpdates={pageProps.draftMode ?? false}
                          debugMode={process.env.NODE_ENV !== "production"}
                        >
                          <QueryClientProvider client={queryClient}>
                            <Hydrate state={pageProps.dehydratedState}>
                              <UserConsentsProvider>
                                <PageTrackingsProvider>
                                  <FavouriteStoreProvider>
                                    <CartChecker />
                                    <PreviewModeBar />
                                    <UsableNetIntegration />
                                    <DidomiIntegration />
                                    <GTMIntegration />
                                    <GlobalNotification />
                                    {CommonUtils.parseBoolean(process.env.NEXT_PUBLIC_ENABLE_COUNTRY_DETECT) && (
                                      <CountryDetectModal />
                                    )}
                                    <Component {...pageProps} />
                                  </FavouriteStoreProvider>
                                </PageTrackingsProvider>
                              </UserConsentsProvider>
                            </Hydrate>
                            <ReactQueryDevtools
                              initialIsOpen={false}
                              position={lang === "ar" ? "bottom-left" : "bottom-right"}
                            />
                          </QueryClientProvider>
                        </ContentfulLivePreviewProvider>
                      </AuthContextProvider>
                    </EvaRecoilRoot>
                  </ServerSidePropsProvider>
                </ConfigurationProvider>
              </TextDirProvider>
            </BreakpointProvider>
          </ErrorBoundary>
        </LocalizationProvider>
      </NextIntlClientProvider>
      <SpeedInsights />
    </>
  );
}
