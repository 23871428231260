import { useTranslations } from "next-intl";
import { useRecoilValue } from "recoil";

import { openedDidomiModalState } from "~/atoms/didomi-modal";
import { useBreakpoint } from "~/contexts/breakpoint";
import useSubscriptions from "~/hooks/use-subscriptions";
import appRoutes from "~/utils/app-routes";
import Constants from "~/utils/constants";
import loyaltyUtils from "~/utils/loyalty-utils";

import Icon from "./icon";
import LinkWrapper from "./link-wrapper";
import styles from "./loyalty-push.module.scss";
import { PagePopup, PagePopupClose, PagePopupContent, PagePopupHeading } from "./page-popup";

type Props = {};

export default function LoyaltyPush(props: Props) {
  const t = useTranslations();
  const breakpoint = useBreakpoint();
  const { hasLoyaltyProgram, userSubscriptions } = useSubscriptions();
  const isUserSubscribedToLoyalty = loyaltyUtils.getLoyaltySubscription(userSubscriptions)?.Status === 2;

  // Recoil state that tells if the Didomi popup is currently opened or not
  const didomiOpened = useRecoilValue(openedDidomiModalState);

  if (!process.env.NEXT_PUBLIC_ENABLE_LOYALTY_PUSH) {
    return null;
  }

  return hasLoyaltyProgram && !isUserSubscribedToLoyalty && !didomiOpened ? (
    <PagePopup
      initialOpen={!localStorage?.getItem(Constants.LOYALTY_PUSH_LOCALSTORAGE_KEY)}
      autoCloseAfterMs={Constants.LOYALTY_PUSH_AUTOCLOSE_TIME}
      onClose={() => localStorage?.setItem(Constants.LOYALTY_PUSH_LOCALSTORAGE_KEY, "1")}
    >
      <PagePopupContent
        style={
          breakpoint === "mobile"
            ? {
                justifyContent: "flex-end",
                insetBlockStart: undefined,
                insetBlockEnd: 12,
              }
            : undefined
        }
      >
        <PagePopupClose className={styles.closeButton}>
          <Icon name="close" width={16} height={16} />
        </PagePopupClose>
        <PagePopupHeading className={styles.loyaltyPushTitle}>{t("generic.loyaltyPushTitle")}</PagePopupHeading>
        <div className={styles.loyaltyPushDescription}>
          {t.rich("generic.loyaltyPushDescription", {
            br: () => <br />,
            loyaltyParagraph: (chunks) => <p>{chunks}</p>,
            strong: (chunks) => <strong>{chunks}</strong>,
            benefitList: (chunks) => <ul className={styles.benefitList}>{chunks}</ul>,
            benefit: (chunks) => <li className={styles.benefitItem}>{chunks}</li>,
            benefitLabel: (chunks) => <span className={styles.benefitLabel}>{chunks}</span>,
            svgIconServices: () => <Icon name="help" className={styles.benefitIcon} />,
            svgIconBirthdayGift: () => <Icon name="birthday" className={styles.benefitIcon} />,
            svgIconWelcomeGift: () => <Icon name="gift" className={styles.benefitIcon} />,
            svgIconSpecialGifts: () => <Icon name="best-clients" className={styles.benefitIcon} />,
            svgIconVIPCare: () => <Icon name="diamond" className={styles.benefitIcon} />,
          })}
          <LinkWrapper href={appRoutes.SIGN_UP} lookAsButton="primary" className={styles.link}>
            {t("generic.loyaltyPushCTA")}
          </LinkWrapper>
        </div>
      </PagePopupContent>
    </PagePopup>
  ) : null;
}
