import "swiper/swiper.css";
import "swiper/css/effect-creative";

import cn from "classnames";
import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useEffect, useRef, useState } from "react";
import SwiperCore, { EffectCreative } from "swiper";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { useBreakpoint, useWindowSize } from "~/contexts/breakpoint";
import Editorials from "~/types/editorials";
import Constants from "~/utils/constants";
import contentfulUtils from "~/utils/contentful-utils";
import { isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";
import useScrollDirection from "~/utils/use-scroll-direction";

import Icon from "../../common/icon";
import styles from "./top-bar-center.module.scss";

type Props = {
  delay: number;
  topBarCenterLinkList?: Entry<Editorials.Link>[];
};

export default function TopBarCenter({ delay = 5, topBarCenterLinkList }: Props) {
  const t = useTranslations();
  const windowSize = useWindowSize();
  const breakpoint = useBreakpoint();
  const router = useRouter();
  const { dir } = useScrollDirection({
    initialDirection: "up",
    thresholdPixels: breakpoint === "desktop" ? Constants.HEADER_HEIGHT_DESKTOP : Constants.HEADER_HEIGHT_MOBILE,
  });

  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const [readMore, setReadMore] = useState(false);
  const [maxCharacters, setMaxCharacters] = useState<(number | undefined)[]>([]);
  const ref = useRef<HTMLDivElement[]>([]);

  const topBarLinks = topBarCenterLinkList?.filter(isActiveEntry);
  const delayInMmilliseconds = delay * 1000;

  const readMoreLabel = t("generic.read_more");
  const readLessLabel = t("generic.read_less");

  useEffect(() => {
    if (dir === "down") {
      setReadMore(false);
    }
  }, [dir]);

  useEffect(() => {
    if (!windowSize.width) {
      return;
    }

    setReadMore(false);
    if (ref?.current?.[0]) {
      setMaxCharacters(ref.current.map(() => undefined));

      setTimeout(() => {
        //calculates the maximum number of characters contained in an element
        const element = ref.current[0];
        const computedStyle = window.getComputedStyle(element);
        const areaElement = element.clientWidth * element.clientHeight;
        const widthCharacter = parseFloat(computedStyle.fontSize) + parseFloat(computedStyle.letterSpacing);
        const areaCharacter = widthCharacter * widthCharacter;

        const maxCharactersWithButton = areaElement / areaCharacter - readMoreLabel.length;

        setMaxCharacters(
          ref.current.map((element) => {
            if (element.scrollHeight > element.clientHeight) {
              return maxCharactersWithButton;
            } else {
              return undefined;
            }
          })
        );
      }, 300);
    }
  }, [readMoreLabel.length, windowSize.width]);

  useEffect(() => {
    const topBar = document.getElementById("topBar");

    if (topBar && ref?.current) {
      if (readMore) {
        setTimeout(() => {
          let maxHeight = 0;
          ref.current.forEach((element, index) => {
            if (index === 0) {
              maxHeight = element.scrollHeight;
            } else if (element.scrollHeight > maxHeight) {
              maxHeight = element.scrollHeight;
            }
          });

          const topBarHeight = maxHeight + 16;
          topBar.style.height = topBarHeight + "px";
        }, 300);
      } else {
        topBar.style.height = "";
      }
    }
  }, [readMore]);

  if (topBarLinks && topBarLinks.length > 0) {
    return (
      <div className={styles.center}>
        {topBarLinks.length > 1 ? (
          <button onClick={() => swiperInstance?.slidePrev()} aria-label={t("a11y.previousSlide")}>
            <Icon name="chevron-left" />
          </button>
        ) : null}
        <Swiper
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          modules={[Autoplay, EffectCreative]}
          centeredSlides
          grabCursor
          loop
          effect="creative"
          creativeEffect={{
            prev: { translate: ["-100%", 0, 0], opacity: 0 },
            next: { translate: ["100%", 0, 0], opacity: 0 },
          }}
          speed={1500}
          autoplay={{
            delay: delayInMmilliseconds,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          className={styles.swiper}
        >
          {topBarLinks.map((link, index) => {
            const { text, url, pageRef, openOnANewTab } = link.fields;
            const inspectorModeTopBarLink = contentfulUtils.useInspectorMode(link);

            return (
              <SwiperSlide
                key={link.sys.id}
                className={cn(
                  styles.swiperSlide,
                  readMore ? styles.swiperSlideReadMore : undefined,
                  maxCharacters[index] ? styles.initialAnimation : undefined
                )}
              >
                <div
                  className={styles.containerSlide}
                  ref={(el) => {
                    if (ref.current && el) {
                      ref.current[index] = el;
                    }
                  }}
                >
                  {url || pageRef ? (
                    <Link
                      {...inspectorModeTopBarLink?.getProps("text")}
                      prefetch={false}
                      href={textUtils.sanitizeContentfulUrl(link, router)}
                      target={openOnANewTab ? "_blank" : undefined}
                      className={cn(styles.link, readMore ? styles.textContainerReadMore : undefined)}
                      aria-label={text}
                    >
                      {!readMore && maxCharacters[index] ? text.slice(0, maxCharacters[index]) + "..." : text}
                    </Link>
                  ) : (
                    <span
                      className={cn(styles.text, readMore ? styles.textContainerReadMore : undefined)}
                      {...inspectorModeTopBarLink?.getProps("text")}
                      aria-label={text}
                    >
                      {!readMore && maxCharacters[index] ? text.slice(0, maxCharacters[index]) + "..." : text}
                    </span>
                  )}
                  {maxCharacters[index] && (
                    <button className={styles.buttonReadMore} onClick={() => setReadMore(!readMore)}>
                      {readMore ? readLessLabel : readMoreLabel}
                    </button>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {topBarLinks.length > 1 ? (
          <button onClick={() => swiperInstance?.slideNext()} aria-label={t("a11y.nextSlide")}>
            <Icon name="chevron-right" />
          </button>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
}
