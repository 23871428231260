import React, { useContext, useEffect, useRef } from "react";
import { useTranslations } from "use-intl";

import { AlgoliaContext } from "~/contexts/algolia";

import Icon from "../common/icon";
import styles from "./algolia-autocomplete-input.module.scss";

export default function AutocompleteInput() {
  const t = useTranslations();

  const inputRef = useRef(null);

  const context = useContext(AlgoliaContext);
  if (!context) {
    throw new Error("AutocompleteInput must be used within an AlgoliaContextProvider");
  }

  const { autocomplete, autocompleteState } = context;

  return (
    <form
      {...(autocomplete.getFormProps({ inputElement: inputRef.current }) as any)}
      className={styles.form}
      aria-labelledby="search-label"
    >
      <label htmlFor="search-input" id="search-label" className={styles.label}>
        <Icon name="lens" aria-hidden />
        <p className="sr-only">{t("a11y.searchProducts")}</p>
      </label>
      <input
        {...(autocomplete.getInputProps({} as any) as any)}
        ref={inputRef}
        id="search-input"
        type="search"
        className={styles.input}
        placeholder={t("generic.search_placeholder")}
        aria-controls="search-results"
      />
      {autocompleteState?.query != "" && (
        <button type="reset" className="" aria-label={t("a11y.clearSearch")}>
          <Icon name="clear" aria-hidden />
        </button>
      )}
      {autocompleteState && "nbProducts" in autocompleteState.context && autocompleteState?.query != "" && (
        <div id="live-region" aria-live="polite" className="sr-only">
          {t("a11y.searchResults", { count: autocompleteState.context.nbProducts as number })}
        </div>
      )}
    </form>
  );
}
