import CommonUtils from "~/utils/common-utils";

// Use ApiUtils.interpolateParams to replace your params dynamically
const endpoints = {
  captainWalletUrl: `loyalty/captain-wallet-url/`,
  socialWall: `social-wall/`,
};

const endpointsV2 = {
  // AUTH
  authCustomer: `auth/customer/`,
  authEmailUpdate: `auth/email-update/`,
  authLoginAsCustomer: `auth/login-as-customer/`,
  authLoyalty: `auth/loyalty/`,
  authPasswordReset: `auth/password-reset/`,
  authProspect: `auth/prospect/`,
  authSocialCustomer: "auth/social-customer/",
  authUnsubscribe: `auth/unsubscribe/`,
  authValidateEmail: `auth/validate-email/`,
  authSendEmailVerification: `auth/send-email-verification/`,
  authSubscriptions: `auth/subscriptions/`,
  // ECO
  ecoGcpsid: "eco/gcpsid",
  ecoOptimalCarrier: "eco/optimal-carrier/",
  ecoPods: "eco/pods/",
  ecoSendEmailVerification: "eco/send-email-verification/",
  // GIFTCARD / EPIPOLI
  giftcardCheckBalance: `epipoli/check-balance/`,
};

type ApiEndpointKeys = keyof typeof endpoints;

type ApiEndpointKeysV2 = keyof typeof endpointsV2;

export class ApiConstants {
  static get baseUrl(): string {
    return process.env.NEXT_PUBLIC_API_BASE_URL || "/api/next";
  }

  static get baseUrlV2(): string {
    return process.env.NEXT_PUBLIC_API_V2_BASE_URL || "/api/v2";
  }

  static get privateBaseUrl(): string {
    return CommonUtils.buildPath(this.baseUrl, "private");
  }

  static readonly endpoints: Record<ApiEndpointKeys, string> = this._mapEndpoints<ApiEndpointKeys>(
    endpoints,
    this.baseUrl
  );

  static readonly endpointsV2: Record<ApiEndpointKeysV2, string> = this._mapEndpoints<ApiEndpointKeysV2>(
    endpointsV2,
    this.baseUrlV2
  );

  private static _mapEndpoints<T extends string = string>(endpointsMap: Record<T, string>, baseUrl: string) {
    return CommonUtils.mapObjectValues(endpointsMap, (v) => [baseUrl, v].join("/"));
  }
}
