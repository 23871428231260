import { Entry } from "contentful";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";

import { countryDialogOpenedState } from "~/atoms/country-dialog";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import crossCountryUtils from "~/utils/cross-country-utils";
import { isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import Container from "../../common/container";
import Icon from "../../common/icon";
import styles from "./top-bar.module.scss";
import TopBarCenter from "./top-bar-center";

const logClick = (item: string) => console.info("%c[WIP]", "color: red;", ` ${item} click`);

type Props = {
  entry: Entry<Editorials.TopBar>;
};

export default function TopBar({ entry }: Props) {
  const router = useRouter();
  const country = crossCountryUtils.getCurrentCountryCode(router);
  const setCountryDialogOpenedState = useSetRecoilState(countryDialogOpenedState);

  function openCountryDialog() {
    setCountryDialogOpenedState(true);
  }

  if (!isActiveEntry(entry)) {
    return null;
  }

  const inspectorModeStoreLink = contentfulUtils.useInspectorMode(entry.fields.storeLink);
  const inspectorModeCustomerHelpCenterLink = contentfulUtils.useInspectorMode(entry.fields.customerHelpCenterLink);

  return (
    <div id="topBar" className={styles.topBar}>
      <Container className={styles.topBarContainer}>
        {entry.fields.storeLink && isActiveEntry(entry.fields.storeLink) ? (
          <Link
            {...inspectorModeStoreLink?.getProps("text")}
            prefetch={false}
            href={textUtils.sanitizeContentfulUrl(entry.fields.storeLink, router)}
            target={entry.fields.storeLink.fields.openOnANewTab ? "_blank" : undefined}
            className={styles.topBarLink}
            onClick={() => logClick("stores")}
          >
            {entry.fields.storeLink.fields.icon && isActiveEntry(entry.fields.storeLink.fields.icon) ? (
              <Icon
                svgMedia={entry.fields.storeLink.fields.icon.fields.svgMedia}
                name={entry.fields.storeLink.fields.icon.fields.svgIconId}
              />
            ) : null}
            <span>{entry.fields.storeLink.fields.text}</span>
          </Link>
        ) : (
          <div />
        )}
        <div className={styles.containerTopBarCenter}>
          <TopBarCenter delay={entry.fields.delay} topBarCenterLinkList={entry.fields.topBarCenterLinkList} />
        </div>
        <div className={styles.topBarWrapper}>
          {entry.fields.customerHelpCenterLink && isActiveEntry(entry.fields.customerHelpCenterLink) ? (
            <Link
              {...inspectorModeCustomerHelpCenterLink?.getProps("text")}
              prefetch={false}
              href={textUtils.sanitizeContentfulUrl(entry.fields.customerHelpCenterLink, router)}
              target={entry.fields.customerHelpCenterLink.fields.openOnANewTab ? "_blank" : undefined}
              className={styles.topBarLink}
              onClick={() => logClick("help")}
            >
              {entry.fields.customerHelpCenterLink.fields.icon &&
              isActiveEntry(entry.fields.customerHelpCenterLink.fields.icon) ? (
                <Icon
                  svgMedia={entry.fields.customerHelpCenterLink.fields.icon.fields.svgMedia}
                  name={entry.fields.customerHelpCenterLink.fields.icon.fields.svgIconId}
                />
              ) : null}
              <span>{entry.fields.customerHelpCenterLink.fields.text}</span>
            </Link>
          ) : null}
          <button className={styles.topBarLink} onClick={() => openCountryDialog()}>
            <span>{router.locale?.replace(/(\w*)\s*-\s*(\w*)/, "$2 | $1")}</span>
            <Image
              unoptimized
              width={16}
              height={16}
              alt={country}
              src={`/next/assets/flags/${country.toLowerCase()}.svg`}
            />
          </button>
        </div>
      </Container>
    </div>
  );
}
