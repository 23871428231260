import "@algolia/autocomplete-theme-classic";

import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import React, { useContext, useMemo } from "react";

import { AlgoliaContext } from "~/contexts/algolia";
import { useBreakpoint } from "~/contexts/breakpoint";
import appRoutes from "~/utils/app-routes";

import Icon from "../common/icon";
import styles from "./search-dialog.module.scss";

export default function AutocompletePanel() {
  const context = useContext(AlgoliaContext);
  const router = useRouter();
  const t = useTranslations();
  const breakpoint = useBreakpoint();

  if (!context) {
    throw new Error("Panel must be used within an AlgoliaContextProvider");
  }
  const { autocompleteState } = context;

  const { recentSearches, querySuggestions, products, popular } = useMemo(() => {
    const recentSearches = autocompleteState?.collections.find((col) => col.source.sourceId === "recentSearchesPlugin");
    const querySuggestions = autocompleteState?.collections.find(
      (col) => col.source.sourceId === "querySuggestionsPlugin"
    );
    const products = autocompleteState?.collections.find((col) => col.source.sourceId === "productsPlugin");
    const popular = autocompleteState?.collections.find((col) => col.source.sourceId === "popularPlugin");
    return {
      recentSearches,
      querySuggestions,
      products,
      popular,
    };
  }, [autocompleteState?.collections]);

  if (!autocompleteState) {
    return;
  }

  return (
    <div className={classNames(styles.searchWrapper)}>
      {!autocompleteState.query ? (
        <>
          <div className={classNames({ [styles.searchBox]: breakpoint == "desktop" }, styles.recentSuggestionWrapper)}>
            {recentSearches && recentSearches.items.length != 0 && (
              <div className={breakpoint == "mobile" ? styles.searchBox : styles.recentSuggestion}>
                <h2 className={styles.searchHeading}>
                  <Icon name="recent" aria-hidden />
                  <span>{t("generic.recentSearches")}</span>
                </h2>
                <ul className={classNames(styles.searchList)} aria-label={t("generic.recentSearches")}>
                  {recentSearches.items.map((item) => {
                    return (
                      <li key={`recent-item-${item.id}`}>
                        {/* @ts-ignore */}
                        {recentSearches.source.templates.item({ item })}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {querySuggestions && querySuggestions.items.length != 0 && (
              <div className={breakpoint == "mobile" ? styles.searchBox : styles.recentSuggestion}>
                <h2 className={styles.searchHeading}>
                  <Icon name="lamp" aria-hidden />
                  <span>{t("generic.suggestions")}</span>
                </h2>
                <ul className={classNames(styles.searchList)} aria-label={t("generic.suggestions")}>
                  {querySuggestions.items.map((item) => (
                    <li key={`suggestion-item-${(item.objectID as string).toLocaleLowerCase().replace(" ", "_")}`}>
                      {/* @ts-ignore */}
                      {querySuggestions.source.templates.item({ item: item })}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {popular && popular.items.length != 0 && (
            <div className={styles.searchBox}>
              <h2 className={styles.searchHeading}>{t("generic.noResultsTitleOverlay")}</h2>
              <ul className={classNames(styles.searchList)} aria-label={t("generic.noResultsTitleOverlay")}>
                {popular.items.map((item) => (
                  <li key={`popular-item-${(item.objectID as string).toLocaleLowerCase().replace(" ", "_")}`}>
                    {/* @ts-ignore */}
                    {popular.source.templates.item({ item: item })}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <>
          {breakpoint == "mobile" && querySuggestions && querySuggestions.items.length != 0 && (
            <div className={classNames(styles.recentSuggestionLive, styles.searchBox)}>
              <h2 className={styles.searchHeading}>
                <Icon name="lamp" aria-hidden />
                <span>{t("generic.suggestions")}</span>
              </h2>
              <ul className={classNames(styles.searchList)} aria-label={t("generic.suggestions")}>
                {querySuggestions.items.map((item) => {
                  return (
                    <li key={`suggestion-item-${(item.objectID as string).toLocaleLowerCase().replace(" ", "_")}`}>
                      {/* @ts-ignore */}
                      {querySuggestions.source.templates.item({ item })}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className={breakpoint == "mobile" && !products ? styles.emptyContainer : styles.searchBox}>
            {breakpoint == "desktop" && querySuggestions && querySuggestions.items.length != 0 && (
              <div className={classNames(styles.recentSuggestionLive, styles.searchBox)}>
                <h2 className={styles.searchHeading}>
                  <Icon name="lamp" aria-hidden />
                  <span>{t("generic.suggestions")}</span>
                </h2>
                <ul className={classNames(styles.searchList)} aria-label={t("generic.suggestions")}>
                  {querySuggestions.items.map((item) => {
                    return (
                      <li key={`suggestion-item-${(item.objectID as string).toLocaleLowerCase().replace(" ", "_")}`}>
                        {/* @ts-ignore */}
                        {querySuggestions.source.templates.item({ item })}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {products && products.items.length != 0 ? (
              <>
                <h2 className={styles.searchHeading}>{t("generic.products")}</h2>
                <ul
                  id="search-results"
                  aria-label={t("a11y.searchContainer")}
                  className={classNames(styles.searchList)}
                >
                  {products.items.map((item) => {
                    return (
                      <li key={`product-item-${(item.objectID as string).toLocaleLowerCase().replace(" ", "_")}`}>
                        {/* @ts-ignore */}
                        {products.source.templates.item({ item })}
                      </li>
                    );
                  })}
                </ul>
                {/* @ts-ignore */}
                {products.source.templates.footer({ state: autocompleteState })}
              </>
            ) : (
              <div className={styles.noResults}>
                <h2>{t("generic.noresults")}</h2>
                <p dangerouslySetInnerHTML={{ __html: t("generic.noresultsparagraph") }} />
              </div>
            )}
            {popular && popular.items.length != 0 && (
              <div className={styles.searchBox}>
                <h2 className={styles.searchHeading}>{t("generic.noResultsTitleOverlay")}</h2>
                <ul className={classNames(styles.searchList)} aria-label={t("generic.noResultsTitleOverlay")}>
                  {popular.items.map((item) => {
                    return (
                      <li key={`popular-item-${(item.objectID as string).toLocaleLowerCase().replace(" ", "_")}`}>
                        {/* @ts-ignore */}
                        {popular.source.templates.item({ item })}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
          <div className={classNames(styles.searchBox, styles.searchAlert)}>
            <p>
              {t.rich("generic.askToKikoHelpCenter", {
                link: (chunks) => {
                  return (
                    <Link href={"/" + router.locale + appRoutes.CUSTOMER_HELP_CENTER} className={styles.link}>
                      {chunks?.toString()}
                    </Link>
                  );
                },
              })}
            </p>
          </div>
        </>
      )}
    </div>
  );
}
