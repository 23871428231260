import { useTranslations } from "next-intl";
import { DetailedHTMLProps, forwardRef, HTMLAttributes, useEffect, useId, useState } from "react";

import productUtils from "~/utils/product-utils";

import Icon from "../common/icon";
import styles from "./product-ratings.module.scss";

export enum ContextEnum {
  default = "default",
  pdp = "pdp",
}

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  parent_backend_id?: string | number;
  average?: number;
  numberOfRatings?: number;
  context?: ContextEnum;
};

declare global {
  var pwr: any;
}

const ProductRatings = forwardRef<HTMLDivElement, Props>(function ProductRatings(
  { context = ContextEnum.default, ...props }: Props,
  ref
) {
  const id = useId();
  const t = useTranslations();
  const average = typeof props.average === "number" ? Math.min(Math.max(props.average, 0), 5) : 0;
  const [roundedAverage, setRoundedAverage] = useState(average);

  useEffect(() => {
    setRoundedAverage(productUtils.roundToNearestQuarter(average));
  }, [average]);

  switch (context) {
    case ContextEnum.pdp:
      // Power Reviews generic rating
      return <div id="pr-categorysnippet" ref={ref} className={styles.powerReviewsContainer} />;
    case ContextEnum.default:
    default:
      return (
        <div
          ref={ref}
          className={styles.container}
          aria-label={t("a11y.ratings_average", { average: roundedAverage, max: 5 })}
        >
          {new Array(5).fill(0).map((_, idx) => {
            if (idx <= roundedAverage - 1) {
              return <Icon key={"full-" + idx + id} name="star-full" width={12} height={12} aria-hidden />;
            } else if (idx <= roundedAverage - 0.75) {
              return (
                <Icon key={"star-75-filled-" + idx + id} name="star-75-filled" width={12} height={12} aria-hidden />
              );
            } else if (idx <= roundedAverage - 0.5) {
              return <Icon key={"half-" + idx + id} name="star-half" width={12} height={12} aria-hidden />;
            } else if (idx <= roundedAverage - 0.25) {
              return (
                <Icon key={"star-25-filled-" + idx + id} name="star-25-filled" width={12} height={12} aria-hidden />
              );
            } else {
              return <Icon key={"empty-" + idx + id} name="star-empty" width={12} height={12} aria-hidden />;
            }
          })}
          {props.numberOfRatings ? <div className={styles.numberOfRatings}>({props.numberOfRatings})</div> : null}
        </div>
      );
  }
});

export default ProductRatings;
