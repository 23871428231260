import { AnimatePresence, motion } from "framer-motion";

import Icon from "../icon";
import styles from "./overlay-generic.module.scss";

type Props = {
  children?: JSX.Element;
};

export default function OverlayGeneric(props: Props) {
  return (
    <>
      <AnimatePresence>
        <motion.div
          key="normalMode"
          className={styles.container}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0 }}
        >
          {!props.children && <div className={styles.background} />}
          <div className={styles.content}>
            <div className={styles.spinner}>
              <div className={styles.circle}></div>
              <div className={styles.iconContainer}>
                <Icon name="hourglass" />
              </div>
            </div>
            {props.children}
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
}
